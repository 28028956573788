import { kanit, poppins } from 'pages/_app';
import Layout from 'components/Layout';
import ProductTypesContext from 'store/productTypes-context';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { ProfileProvider } from 'context/ProfileContext';

import GTMHelper from 'lib/GTMHelper';

const PRODUCT_OVERVIEW_STORAGE_KEY = 'productOverviewUrl';

const getQueryParams = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());
  return JSON.stringify(params);
};

export default function DeprecatedLayout({ children, ...pageProps }: any) {
  const { asPath } = useRouter();

  useEffect(() => {
    if (window) {
      const storage = window.localStorage;
      const { hash } = window.location;
      const productOverviewUrl = storage.getItem(PRODUCT_OVERVIEW_STORAGE_KEY);

      if (
        productOverviewUrl &&
        productOverviewUrl !== asPath &&
        productOverviewUrl !== hash
      ) {
        GTMHelper.customRcHomeCategory('');

        storage.removeItem(PRODUCT_OVERVIEW_STORAGE_KEY);
      }
    }
  }, [asPath]);

  // SET Cookie
  useEffect(() => {
    // Set Cookies
    Cookies.set('lgt_campaign_query_params', getQueryParams(), {
      expires: 3,
      path: '/',
      domain: window.location.host,
    });

    if (!window) return;
    Cookies.set('lgt_referer_url', window.location.href, {
      expires: 3,
      path: '/',
      domain: window.location.host,
    });
  }, []);

  const {
    layout,
    notifications,
    popup,
    settings,
    page,
    isMultipleLang,
    breadcrumbs,
  } = pageProps;

  if (!layout) {
    throw new Error('layout is not defined');
  }

  return (
    <div
      style={{
        fontFamily: `${poppins.style.fontFamily}, ${kanit.style.fontFamily}`,
      }}
    >
      <ProfileProvider>
        <Layout
          layout={layout}
          notifications={notifications}
          popup={popup}
          settings={settings}
          seo={page?.seo}
          slug={pageProps?.page?.slug}
          isMultipleLang={isMultipleLang}
          staticLinkBuilding={layout.staticLinkBuilding}
          breadcrumbs={breadcrumbs}
        >
          <ProductTypesContext.Provider value={pageProps.productTypes}>
            {children}
          </ProductTypesContext.Provider>
        </Layout>
      </ProfileProvider>
    </div>
  );
}
