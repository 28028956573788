export const baseUrl = process.env.NEXT_PUBLIC_BASE_URL || '';
export const lgtUrl = process.env.NEXT_PUBLIC_LGT_URL || '';
export enum WebsiteLocales {
  th = 'th',
  en = 'en',
}

export const MappedProfileKeys = {
  RC_GOOGLE_KEY: 'rc-google-id-token',
  PROFILE_KEY: 'profile',
};

export enum LGT {
  qualifiedStatus = 'qualified',
  rejectedStatus = 'rejected',
  toCallStatus = 'to-call',
  duplicatedStatus = 'duplicated',
  errorStatus = 'error',
  customEvent = 'in-page-media',
  url = '/api/create-lead',
  leadSource = 'Website',
}
