import {
  BlogsProps,
  BlogsTabProps,
  CustomerReviewsProps,
  FaqAndSeoProps,
  FaqAndSeoTabProps,
  LogoProps,
  NewsletterProps,
  TextBoxProps,
  VideoProps,
  LinkBuildingProps,
  ParagraphAndImageProps,
  ProductOverviewProps,
  SliderWithCtaProps,
  SlideShowProps,
  TableProps,
  ImpactTextProps,
  MobileFirstBannerProps,
  ProductListingModuleProps,
  ProductListingTwoColumnProps,
  CategoryModuleProps,
  ShortQuestionnaireProps,
  BannerInPageFormProps,
  LoanWizardProps,
  MapAndTextBoxProps,
  BiographyProps,
  BreadcrumbProps,
  YouMayAlsoLikeProps,
  LoanWizardTextInputProps,
  ProductListingAndTabsProps,
  BiographyNewProps,
  ProductCardsListingProps,
  BlogsTab2Props,
  LeadFormProps,
} from 'interfaces/cms/content';

export enum DynamicContentTypes {
  BLOGS = 'blogs',
  BLOGSTAB = 'blogsTab',
  BLOGSTAB2 = 'blogsTab2',
  REVIEWS = 'reviews',
  FAQSEO = 'faqSeo',
  FAQSEOTAB = 'faqSeoTab',
  TEXTBOX = 'textBox',
  NEWSLETTER = 'newsletter',
  VIDEO = 'video',
  LOGOS = 'logos',
  LINKBUILDING = 'linkBuildingModule',
  PARAGRAPHIMAGE = 'paragraphImages',
  PRODUCTOVERVIEW = 'productOverview',
  SLIDERWITHCTA = 'sliderWithCta',
  SLIDESHOW = 'slideShow',
  TABLE = 'table',
  IMPACTTEXT = 'impactText',
  MOBILEFIRSTBANNER = 'mobileFirstBanner',
  PRODUCTLISTING = 'productListing',
  PRODUCTLISTINGTWOCOLUMNS = 'productListingTwoColumns',
  CATEGORY = 'categoryModule',
  SHORTQUESTIONNAIRE = 'shortQuestionnaire',
  BANNERINPAGEFORM = 'bannerInPageForm',
  LOANWIZARD = 'loanWizard',
  MAPANDTEXTBOX = 'mapAndTextbox',
  BIOGRAPHY = 'biography',
  BIOGRAPHYNEW = 'biographyNew',
  BREADCRUMB = 'breadcrumb',
  YOUMAYALSOLIKE = 'youMayAlsoLike',
  LOANWIZARDTEXTINPUT = 'loanWizardTextInput',
  PRODUCTLISTINGANDTABS = 'productListingAndTabs',
  PRODUCTCARDSLISTING = 'productCardsListing',
  LEADFORM = 'leadForm',
}

export type DynamicContentProps =
  | NewsletterProps
  | TextBoxProps
  | FaqAndSeoProps
  | FaqAndSeoTabProps
  | CustomerReviewsProps
  | VideoProps
  | LogoProps
  | LinkBuildingProps
  | BlogsProps
  | BlogsTabProps
  | BlogsTab2Props
  | ParagraphAndImageProps
  | ProductOverviewProps
  | SliderWithCtaProps
  | SlideShowProps
  | TableProps
  | ImpactTextProps
  | MobileFirstBannerProps
  | ProductListingModuleProps
  | CategoryModuleProps
  | ShortQuestionnaireProps
  | ProductListingTwoColumnProps
  | BannerInPageFormProps
  | LoanWizardProps
  | MapAndTextBoxProps
  | BiographyProps
  | BiographyNewProps
  | BreadcrumbProps
  | YouMayAlsoLikeProps
  | LoanWizardTextInputProps
  | ProductListingAndTabsProps
  | ProductCardsListingProps
  | LeadFormProps;
